<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('fair_report.bank_report') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col lg="12" sm="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                <b-row>
                  <b-col sm="4">
                    <b-form-group
                      label-for="year"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="year"
                        :options="yearList"
                        v-model="search.year"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      label-for="fair_id"
                    >
                      <template v-slot:label>
                        {{ $t('tradeFairConfig.fair_name') }}
                      </template>
                      <b-form-select
                        disabled
                        plain
                        id="fair_id"
                        :options="dhakaFairList"
                        v-model="search.fair_id"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      label-for="date"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.from_date') }}
                      </template>
                      <date-picker
                        id="start_date"
                        class="form-control"
                        v-model="search.start_date"
                        :placeholder="$t('globalTrans.select')"
                      >
                      </date-picker>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      label-for="date"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.to_date') }}
                      </template>
                      <date-picker
                        id="end_date"
                        class="form-control"
                        v-model="search.end_date"
                        :placeholder="$t('globalTrans.select')"
                      >
                      </date-picker>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider name="Payment Type" vid="payment_type" rules="required|min_value:1" v-slot="{ errors }">
                      <b-form-group
                        label-for="payment_type"
                      >
                        <template v-slot:label>
                          {{ $t('fair_report.payment_type') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          id="payment_type"
                          :options="paymentTypeList"
                          v-model="search.payment_type"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="4" v-if="search.payment_type === 2">
                    <ValidationProvider name="Stall Category" vid="stall_category" rules="" v-slot="{ errors }">
                    <b-form-group
                      label-for="stall_category"
                    >
                      <template v-slot:label>
                        {{ $t('fair_report.space_category') }}
                      </template>
                      <b-form-select
                        plain
                        id="stall_category"
                        :options="stallCategoryList"
                        v-model="search.stall_category"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                    </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                    <b-button :disabled="isResult" type="submit" size="sm" variant="primary" class="mt-20">
                      <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- <body-card v-if="finalList.length"> -->
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('fair_report.bank_report') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <b-button v-show="showHide" variant="primary" size="sm" @click="pdfExport" class="float-right">
            {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
            <template v-slot:projectNameSlot>
              {{ }}
            </template>
            {{ $t('fair_report.bank_report') }}
          </list-report-head>
          <b-row class="mb-2">
            <b-col md="12" class="text-left">
              <strong>{{ parseInt(search.fair_id) ? getSearchFairName(search.fair_id) : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="6" v-if="search.start_date">
              <span>{{ $t('globalTrans.from_date') }}</span>:
              <strong>{{ search_param.start_date |dateFormat  }}</strong>
            </b-col>
            <b-col md="6" class="text-right" v-if="search.end_date">
              <span>{{ $t('globalTrans.to_date') }}</span>:
              <strong>{{ search_param.end_date |dateFormat }}</strong>
            </b-col>
            <b-col md="6" class="text-left" v-if="search.payment_type">
              <span>{{ $t('fair_report.payment_type') }}</span>:
              <strong v-if="search_param.payment_type == 1">{{ currentLocale == 'bn' ? 'প্রসেসিং ফি এবং নিরাপত্তা জামানত' : 'Processiong Fee & Security Fee'}}</strong>
              <strong v-if="search_param.payment_type == 2">{{ currentLocale == 'bn' ? 'বরাদ্দ ফি' : 'Allotment Fee' }}</strong>
            </b-col>
            <b-col md="6" class="text-right" v-if="search.stall_category && search.payment_type === 2">
              <span>{{ $t('fair_report.space_category') }}</span>:
              <strong>{{ getCategoryName(search_param.stall_category) }}</strong>
            </b-col>
            <b-col md="12" class="text-left mt-3" v-if="search.payment_type === 2" v-show="showHide">
              <strong>{{ $t('fair_report.no_of_stall_call') }} </strong>  : {{ $n(noOfStall) }} {{ currentLocale == 'bn' ? 'টি' : '' }}<br>
              <strong>{{ $t('fair_report.no_of_application') }} </strong>  : {{ $n(count_app.total_app) }} {{ currentLocale == 'bn' ? 'টি' : '' }}<br>
              <strong>{{ $t('fair_report.no_of_allocation') }} </strong>  : {{ $n(count_app.b_stall) }} {{ currentLocale == 'bn' ? 'টি' : '' }}<br>
              <strong>{{ $t('globalTrans.payment') }} </strong>  : {{ $n(noOfpayment) }} {{ currentLocale == 'bn' ? 'টি' : '' }}<br>
              <strong>{{ $t('fair_report.remaining') }} </strong>  : {{ $n(parseInt(count_app.b_stall - noOfpayment)) }} {{ currentLocale == 'bn' ? 'টি' : '' }}<br>
            </b-col>
          </b-row>
          <b-row>
            <b-overlay>
              <b-row>
                <b-col lg="12" sm="12" class="table-responsive">
                  <table style="width:100%;">
                    <thead>
                      <tr>
                        <th>{{ $t('globalTrans.sl_no') }}</th>
                        <th>{{ $t('globalTrans.application_id') }}</th>
                        <th>{{ $t('exportCipReport.org_name_address') }}</th>
                        <th>{{ $t('fair_report.applicant_name_phone_number') }}</th>
                        <th>{{ $t('fair_report.transaction_no_payment_date') }}</th>
                        <th>{{ $t('globalTrans.amount') }}</th>
                      </tr>
                    </thead>
                    <tbody v-if="finalList.length" v-show="showHide">
                      <tr v-for="(item, index) in finalList" :key="index">
                        <td class="text-center"> {{$n(index + 1)}}</td>
                        <td>{{ item.app_auto_id }}</td>
                        <td>
                          <strong>{{ currentLocale == 'bn' ?  item.comp_name_bn : item.comp_name_en }}</strong>,
                          <br>
                          {{ AddressHelper.getCommonAddress(item) }}
                        </td>
                        <td>
                          {{ currentLocale === 'bn' ? item.name_bn : item.name_en }}
                          <br>
                          {{ item.mobile_no | mobileNumber }}
                        </td>
                        <td>
                          <span v-if="search.payment_type === 1">
                            {{ item.app_payment.transaction_no }}
                            <br>
                            {{ item.app_payment.pay_date | dateFormat }}
                            <br>
                            <a :href="'https://voucher.sblesheba.com:8877/Receipt/' + item.app_payment.sonali_transaction_id" target="_blank">{{ $t('globalTrans.link') }}</a>
                          </span>
                          <span v-if="search.payment_type === 2">
                            <table style="font-size: 12px;" class="p-0 m-0">
                              <tr v-for="(item1, index1) in item.app_stall_payment_pay" :key="index1">
                                <td style="font-size: 12px;">{{ item1.transaction_no }}</td>
                                <td style="font-size: 12px;">{{ item1.pay_date | dateFormat }}</td>
                                <td style="font-size: 12px;"><a
                                :href="'https://voucher.sblesheba.com:8877/Receipt/' + item1.sonali_transaction_id"
                                target="_blank"
                              >
                                <!-- {{ $t('fair_report.voucher_link') }} -->
                                {{ $t('globalTrans.link') }}
                              </a></td>

                              </tr>
                            </table>
                            <!-- {{ getTransactionNo(item.app_stall_payment_pay) }}<br />
                            {{ getTranDate(item.app_stall_payment_pay) }} <br>
                            <span v-for="(payment, paymentIndex) in item.app_stall_payment_pay" :key="paymentIndex">
                              <a
                                :href="'https://voucher.sblesheba.com:8877/Receipt/' + payment.sonali_transaction_id"
                                target="_blank"
                              >
                                {{ $t('fair_report.voucher_link') }}
                              </a>
                              <br /> -->
                            <!-- </span> -->
                          </span>
                        </td>
                        <td class="text-right">
                          <span v-if="search.payment_type === 1">
                            {{ $n(item.app_payment.total_money) }}
                          </span>
                          <span v-else>
                              {{ $n(getStallFree(item.app_stall_payment_pay)) }}<br>
                              {{ $t('externalTradeFair.tax_10') + ':' + $n(getVat(item.app_stall_payment_pay)) }}<br>
                              {{ $t('externalTradeFair.vat_15') + ':' + $n(getTex(item.app_stall_payment_pay)) }}<br>
                              {{ $t('globalTrans.total') + ':' + $n(getTotalStallFree(item.app_stall_payment_pay)) }}
                          </span>
                        </td>
                      </tr>
                      <tr v-if="search.payment_type === 2">
                        <td colspan="5" class="text-right">{{ $t('globalTrans.total') }}</td>
                        <td class="text-right">
                            {{ $n(getMoney(finalList)) }}
                        </td>
                      </tr>
                      <tr v-if="search.payment_type === 2">
                        <td colspan="5" class="text-right">{{ $t('globalTrans.total') + ' ' + $t('externalTradeFair.tax_10') }}</td>
                        <td class="text-right">
                            {{ $n(getTotalVatMoney(finalList)) }}
                        </td>
                      </tr>
                      <tr v-if="search.payment_type === 2">
                        <td colspan="5" class="text-right">{{ $t('globalTrans.total') + ' ' + $t('externalTradeFair.vat_15') }}</td>
                        <td class="text-right">
                            {{ $n(getTotalTaxMoney(finalList)) }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="5" class="text-right">{{ $t('globalTrans.totalAmount') }}</td>
                        <td class="text-right">
                          <span v-if="search.payment_type === 1">
                            {{ $n(getTotalMoney(finalList)) }}
                          </span>
                          <span v-else>
                            {{ $n(getTotalStallMoney(finalList)) }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="6" class="text-center"> {{ $t('fair_report.not_found') }} </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-overlay>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { ditfBankReport } from '../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ModalBaseMasterList from '@/mixins/list'
import AddressHelper from '@/utils/area-type-address'
import { dateFormat } from '@/utils/fliter'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    ListReportHead
  },
  created () {
    const currentFiscalYear = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.$store.state.currentFiscalYearId)
    this.search.year = new Date(currentFiscalYear.end_date).getFullYear()
    // this.loadData()
  },
  data () {
    return {
      AddressHelper: AddressHelper,
      valid: null,
      baseUrl: internationalTradeFairServiceBaseUrl,
      sortBy: '',
      detailsItemId: '',
      isResult: false,
      showHide: false,
      itemData: [],
      finalList: [],
      dhakaFairList: [],
      count_app: '',
      noOfStall: 0,
      noOfpayment: 0,
      search: {
        year: 0,
        fair_id: 0,
        stall_category: 0,
        payment_type: 0,
        start_date: '',
        end_date: ''
      },
      search_param: {
        year: 0,
        fair_id: 0,
        stall_category: 0,
        payment_type: 0,
        start_date: '',
        end_date: ''
      },
      totalStallAmount: 0,
      grantTotalStallAmount: 0,
      totalVatAmount: 0,
      totalTaxAmount: 0,
      actions: {
        edit: true,
        details: false,
        toogle: true,
        delete: false
      }
    }
  },
  watch: {
    'search.year': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dhakaFairList = this.dhakaFair()
      }
    },
    'search.payment_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showHide = false
        this.search.stall_category = 0
        // this.loadData()
      }
    },
    'search.stall_category': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        // this.getFloorPrice(newVal)
        this.showHide = false
      }
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
      }
    }
  },
  computed: {
    paymentTypeList () {
      return [
        { text: this.$i18n.locale === 'bn' ? 'প্রসেসিং ফি এবং নিরাপত্তা জামানত' : 'Processiong Fee & Security Fee', value: 1 },
        { text: this.$i18n.locale === 'bn' ? 'বরাদ্দ ফি' : 'Allotment Fee', value: 2 }
      ]
    },
    yearList () {
      return this.$store.state.commonObj.yearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item, text: this.EngBangNum(item.toString()) }
        } else {
          return { value: item, text: item }
        }
      })
    },
    circularTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    stallTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.stallTypeList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    stallCategoryList () {
      return this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getCategoryName (id) {
      const companyType = this.stallCategoryList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    getTransactionNo (datas) {
      const lesson = []
      datas.map((item) => {
          lesson.push(item.transaction_no)
      })
      return lesson.join(' ')
    },
    getTranDate (datas) {
      const lesson = []
      datas.map((item) => {
          lesson.push(dateFormat(item.pay_date))
      })
      return lesson.join(' ')
    },
    voucherDownload2 (voucher) {
      return 'https://voucher.sblesheba.com:8877/Receipt/' + voucher
    },
    getTotalMoney (item) {
      if (!Array.isArray(item) || item.length === 0) {
        return 0
      }

      return item.reduce((amount, element) => {
        return amount + Number(element.app_payment.total_money || 0)
      }, 0)
    },
    getTotalStallMoney (items) {
      let totalAmount = 0
      if (Array.isArray(items)) {
        items.forEach(item => {
          if (Array.isArray(item.app_stall_payment_pay)) {
            item.app_stall_payment_pay.forEach(el => {
              totalAmount += Number(el.payment_amount)
            })
          }
        })
      }
      this.grantTotalStallAmount = totalAmount
      return totalAmount
    },
    getMoney (items) {
      let totalAmount = 0
      let vatAmount = 0
      let taxAmount = 0
      if (Array.isArray(items)) {
        items.forEach(item => {
          if (Array.isArray(item.app_stall_payment_pay)) {
            item.app_stall_payment_pay.forEach(el => {
              totalAmount += Number(el.payment_amount)
              vatAmount += Number(el.vat)
              taxAmount += Number(el.tax)
            })
          }
        })
      }
      this.totalStallAmount = (totalAmount - vatAmount - taxAmount)
      return (totalAmount - vatAmount - taxAmount)
    },
    getTotalVatMoney (items) {
      let vatAmount = 0
      if (Array.isArray(items)) {
        items.forEach(item => {
          if (Array.isArray(item.app_stall_payment_pay)) {
            item.app_stall_payment_pay.forEach(el => {
              vatAmount += Number(el.vat)
            })
          }
        })
      }
      this.totalVatAmount = vatAmount
      return vatAmount
    },
    getTotalTaxMoney (items) {
      let taxAmount = 0
      if (Array.isArray(items)) {
        items.forEach(item => {
          if (Array.isArray(item.app_stall_payment_pay)) {
            item.app_stall_payment_pay.forEach(el => {
              taxAmount += Number(el.tax)
            })
          }
        })
      }
      this.totalTaxAmount = taxAmount
      return taxAmount
    },
    getTotalStallFree (item) {
      let amount = 0
      item.forEach(el => {
        amount += Number(el.payment_amount)
      })
      return amount
    },
    getStallFree (item) {
      let amount = 0
      let vat = 0
      let tax = 0
      item.forEach(el => {
        amount += Number(el.payment_amount)
        vat += Number(el.vat)
        tax += Number(el.tax)
      })
      return (amount - vat - tax)
    },
    getVat (item) {
      let vat = 0
      item.forEach(el => {
        vat += Number(el.vat)
      })
      return vat
    },
    getTex (item) {
      let tax = 0
      item.forEach(el => {
        tax += Number(el.tax)
      })
      return tax
    },
    circularType (fair) {
      const sectorObj = this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.find(el => el.value === parseInt(fair))
      if (sectorObj !== undefined) {
        return this.currentLocale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    getSearchParamName (Id, paramName) {
      let arrayData
      if (paramName === 'cType') {
        arrayData = this.$store.state.TradeFairService.ditfCommonObj.circularTypeList
      } else {
        return ''
      }
      const Obj = arrayData.find(el => el.value === parseInt(Id))
      if (typeof Obj !== 'undefined') {
        return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
      } else {
        return ''
      }
    },
    dhakaFair () {
      this.search.fair_id = 0
      return this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.filter(item => item.year === this.search.year).map(item => {
        if (item !== '') {
          this.search.fair_id = item.value
        }
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.isResult = true
      this.showHide = true
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, ditfBankReport, this.search)
      if (result.success) {
        this.search_param = result.search_data
        this.finalList = result.data
        this.count_app = result.count_app
        this.noOfpayment = result.data_count
        this.noOfStall = parseInt(result.count_app.totalStallQuantity)
        this.isResult = false
        // this.getFloorPrice(this.search.stall_category)
      } else {
        this.isResult = false
        this.finalList = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getCompanyTypeName (id) {
      const companyType = this.$store.state.TradeFairService.commonObj.companyTypeList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    getSearchFairName (id) {
      const companyType = this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    getStallType (id) {
      const stallType = this.$store.state.TradeFairService.ditfCommonObj.stallTypeList.find(obj => obj.value === parseInt(id))
      if (typeof stallType !== 'undefined') {
        return this.$i18n.locale === 'en' ? stallType.text_en : stallType.text_bn
      } else {
        return ''
      }
    },
    async pdfExport () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 2, year: this.search_param.year, payment_type: this.search.payment_type, grantTotalStallAmount: this.grantTotalStallAmount, totalVatAmount: this.totalVatAmount, totalTaxAmount: this.totalTaxAmount, no_of_application: this.count_app.total_app, no_of_allocation: this.count_app.b_stall, remaining: parseInt(this.count_app.b_stall - this.noOfpayment), no_of_stall: this.noOfStall, totalStallAmount: this.totalStallAmount, noOfpayment: this.noOfpayment, fair_id: this.search_param.fair_id, start_date: this.search.start_date, end_date: this.search.end_date, stall_category: this.search.stall_category })
      const result = await RestApi.getPdfData(internationalTradeFairServiceBaseUrl, ditfBankReport, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getFloorPrice (catId) {
      const cat = this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.find(item => item.value === catId)
      if (cat !== undefined) {
        this.noOfStall = cat.stall_qty
      } else {
        this.noOfStall = 0
      }
    }
  }
}
</script>
<style scoped>
  table, th, td {
    border: 1px solid black;
    padding:12px;;
  }
</style>
